import React from "react";
import classnames from "classnames";
import * as Cookies from "js-cookie";
import axios from "axios";
import './style.css'
import {
  Redirect,
  Link,
} from "react-router-dom";
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Navbar,
  Nav,
  Container,
} from "reactstrap";

import NotificationDropdown from "./NotificationDropdown";
import { NotificationProvider } from "./NotificationContext";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: false,
      color: "navbar-transparent",
      title: "",
      notification: [],
      msgLength: "",
      msgLengthJob: "",
      notificationJob: [],
      profilePictureUrl:""
      // msgLengthChat : '',
    };
  }

  logOut = (e) => {
    axios
      .post("/api/signOut", this.state)
      .then((response) => {})
      .catch((error) => {
        console.log(error);
      });
    localStorage.clear();
    Cookies.remove("user_id1");
    this.setState({ redirect: true });
  };

  componentDidMount() {
    window.addEventListener("resize", this.updateColor);
    // const user_id = JSON.parse(localStorage.getItem('user_id'))
    // this.setState({ user_id })

    // ////////////////////////////////////////////////////////
    // axios.post("/api/getupdates", { ...this.state, user_id })
    //   .then(response => { // then print response status
    //     //console.log(response)
    //     const msgLengthJob = response.data.length;
    //     this.setState({ msgLengthJob });
    //     const notificationJob = response.data;
    //     this.setState({ notificationJob })
    //     // this.intervalID = setTimeout(this.componentDidMount.bind(this), 10000);
    //   })
    //   .catch(error => {
    //     //console.log(error);
    //     this.setState({ notificationJob: [] })
    //     this.setState({ msgLengthJob: '' })
    //     // this.intervalID = setTimeout(this.componentDidMount.bind(this), 10000);
    //   })
  }
  componentDidUpdate(e) {
    if (
      window.outerWidth < 993 &&
      e.history.location.pathname !== e.location.pathname &&
      document.documentElement.className.indexOf("nav-open") !== -1
    ) {
      document.documentElement.classList.toggle("nav-open");
    }
  }
  componentWillMount() {
    const f_name = JSON.parse(localStorage.getItem("f_name"));
    this.setState({ f_name });
    const title = JSON.parse(localStorage.getItem("title"));
    this.setState({ title });
    const lname = JSON.parse(localStorage.getItem("lname"));
    this.setState({ lname });
    const user_profile = JSON.parse(localStorage.getItem("user_profile"));
   // this.setState({ user_profile });
   // console.log(user_profile);
    const andre = JSON.parse(localStorage.getItem("andre"));
    this.setState({ andre });
    this.fetchProfilePicture(user_profile);
  }

  fetchProfilePicture = async (picture) => {
    if (picture) {
      try {
     
        const response = await axios.get(`/api/getProfilePicture/${picture}`, {
          responseType: "blob", // We expect binary data
        });

        // Create a URL for the fetched binary data
        const imageUrl = URL.createObjectURL(response.data);
        this.setState({profilePictureUrl:imageUrl});
      } catch (error) {
        console.error("Error fetching profile picture:", error);
        //setCompanyLogoUrl("../../img2/profile_user_avatar.png"); // Fallback to default avatar
      } finally {
        //setIsLoadingProfilePicture(false);
      }
    }
  };
  // function that adds color white/transparent to the navbar on resize (this is for the collapse)
  updateColor = () => {
    if (window.innerWidth < 993 && this.state.collapseOpen) {
      this.setState({
        color: "bg-white",
      });
    } else {
      this.setState({
        color: "navbar-transparent",
      });
    }
  };
  componentWillUnmount() {
    clearTimeout(this.intervalID);
  }
  // this function opens and closes the sidebar on small devices
  toggleSidebar = () => {
    document.documentElement.classList.toggle("nav-open");
  };
  // this function opens and closes the collapse on small devices
  // it also adds navbar-transparent class to the navbar when closed
  // ad bg-white when opened
  toggleCollapse = () => {
    let newState = {
      collapseOpen: !this.state.collapseOpen,
    };
    if (!this.state.collapseOpen) {
      newState["color"] = "bg-white";
    } else {
      newState["color"] = "navbar-transparent";
    }
    this.setState(newState);
  };
  handleRemove(id) {
    this.state.notification.splice(id, 1);
    this.setState({ notification: this.state.notification });
  }
  render() {
    //  const [list, setList] = React.useState(notification);

    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to="/login" />;
    }
    const messageList = this.state.notificationJob
      .slice(0, 10)
      .map((notificationJob, id) => (
        <div key={id}>
          <DropdownItem
            href={`/admin/apply-job/${notificationJob.job_id}`}
            // onClick={e => e.preventDefault()}
          >
            <b>{notificationJob.company_name}</b> <br />
            <small>
              {notificationJob.job_function} {notificationJob.profession}{" "}
            </small>
            <br></br>
            <small>Status: {notificationJob.candidate_status}</small>
          </DropdownItem>

          <hr />
        </div>
      ));

    const getInitials = (f_name, lname) => {
      if (!f_name || !lname) return '';
      return `${f_name[0]}${lname[0]}`;
    };

    return (
      <>
        <NotificationProvider>
          <Navbar
            className={classnames(
              "navbar-absolute fixed-top",
              this.state.color
            )}
            expand="lg"
          >
            <Container fluid>
              <div className="navbar-wrapper">
                <div className="navbar-minimize">
                  <Button
                    className="btn-icon btn-round"
                    color="default"
                    id="minimizeSidebar"
                    onClick={this.props.handleMiniClick}
                  >
                    <i className="nc-icon nc-minimal-right text-center visible-on-sidebar-mini" />
                    <i className="nc-icon nc-minimal-left text-center visible-on-sidebar-regular" />
                  </Button>
                </div>

                <div
                  className={classnames("navbar-toggle", {
                    toggled: this.state.sidebarOpen,
                  })}
                >
                  <button
                    className="navbar-toggler"
                    type="button"
                    onClick={this.toggleSidebar}
                  >
                    <span className="navbar-toggler-bar bar1" />
                    <span className="navbar-toggler-bar bar2" />
                    <span className="navbar-toggler-bar bar3" />
                  </button>
                </div>
              </div>
              <button
                aria-controls="navigation-index"
                aria-expanded={this.state.collapseOpen}
                aria-label="Toggle navigation"
                className="navbar-toggler"
                data-toggle="collapse"
                type="button"
                onClick={this.toggleCollapse}
              >
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
                <span className="navbar-toggler-bar navbar-kebab" />
              </button>

              <Collapse
                className="justify-content-end"
                navbar
                isOpen={this.state.collapseOpen}
              >
                <Nav navbar>
                  <div style={{ display: "flex", paddingRight: "20px" }}>
                    {" "}
                    <NotificationDropdown />
                    {/* <i className="nc-icon nc-bell-55" /> */}
                    <div className="sidebar-wrapper" ref="sidebar">
                    <Link to="/admin/candidate-profilee">  
                      <div
                        className="user"
                        style={{ display: "flex", marginLeft: "5px" }}
                      >
                        <div className="photo" style={{ marginRight: "3px" }}>
                          {this.state.user_profile === null ? (
                            <div
                              style={{
                                width: 30,
                                height: 30,
                                borderRadius: "50%",
                                backgroundColor: "#ccc",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                fontSize: "15px",
                              }}
                            >
                              {getInitials(this.state.f_name, this.state.lname)}
                            </div>
                          ) : (
                            <img
                            style={{
                              width: 30,
                              height: 30,
                              borderRadius: "50%",
                              backgroundColor: "#ccc",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              fontSize: "15px",
                            }}
                              src={this.state.profilePictureUrl}
                              alt="Pic"
                            />
                          )}
                        </div>
                        <div className="info">
                          
                            <span>{this.state.f_name}</span>
                          
                        </div>
                      </div>
                      </Link>
                    </div>
                    <UncontrolledDropdown className="btn-rotate" nav>
                      <DropdownToggle
                        aria-haspopup={true}
                        caret
                        color="default"
                        data-toggle="dropdown"
                        id="navbarDropdownMenuLink"
                        nav
                      >
                        <i
                          className="nc-icon nc-settings-gear-65"
                          style={{
                            fontSize: "20px",
                            top: "-1px",
                            marginLeft: "5px",
                          }}
                        />
                        <span
                          className="d-lg-none d-md-block"
                          style={{ display: "none" }}
                        >
                          Actions
                        </span>
                      </DropdownToggle>
                      <DropdownMenu
                        right 
                        aria-labelledby="navbarDropdownMenuLink"
                      >
                        {/* <DropdownItem tag={Link} to="/admin/candidate-profilee">
                          Profil
                        </DropdownItem> */}
                        <DropdownItem tag={Link} to="/admin/account">
                          Einstellungen
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/admin/feedback">
                          Feedback
                        </DropdownItem>
                        <DropdownItem tag={Link} to="/admin/message">
                          Kontakt
                        </DropdownItem>
                        <DropdownItem
                          tag={Link}
                          target="_blank"
                          rel="noopener noreferrer"
                          to="/Impressum"
                        >
                          Impressum
                        </DropdownItem>
                        <DropdownItem
                          tag={Link}
                          target="_blank"
                          rel="noopener noreferrer"
                          to="/Datenschutz"
                        >
                          Datenschutz
                        </DropdownItem>
                        <DropdownItem
                          tag={Link}
                          target="_blank"
                          rel="noopener noreferrer"
                          to="/AGB"
                        >
                          AGB
                        </DropdownItem>
                        <DropdownItem onClick={this.logOut}>
                          Abmelden
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </Nav>
              </Collapse>
            </Container>
          </Navbar>
        </NotificationProvider>
      </>
    );
  }
}

export default AdminNavbar;
