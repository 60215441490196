import React, { useRef, useState } from 'react';
import { useNotifications } from './NotificationContext';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from "reactstrap";
import axios from 'axios';
import { Link } from 'react-router-dom';

function NotificationDropdown() {
    const { notifications, fetchNotifications } = useNotifications();
    const dropdownRef = useRef(null);
    const [maxHeight, setMaxHeight] = useState(600); // default value

    const handleNotificationClick = async (notification) => {
        const user_id = localStorage.getItem('user_id'); // Assuming user_id is stored in local storage

        await axios.post('/api/updateNotificationStatus', {
            job_id: notification.job_id || notification.chat_id,
            app_id: notification.app_id,
            id: notification.id || notification.message_id,
            type: notification.type,
            user_id: user_id, // Include user_id in the request
        });

        // Fetch the notifications again
        fetchNotifications();
    };

    // Function to get the appropriate URL based on notification type
    const getNotificationLink = (notification) => {
        switch (notification.type) {
            case 'new_comment':
                return `/admin/Bewerbungen?status=${notification.status}&appid=${notification.app_id}&jobid=${notification.job_id}&recipient=${notification.recipient_type}`;
            case 'new_contract':
                return `/admin/KandidateContractPage/${notification.applyjob_id}`;
            case 'new_document':
                return `/admin/Bewerbungen?status=${notification.status}&appid=${notification.app_id}&jobid=${notification.job_id}`;
            case 'new_message':
                return `/admin/chat/${notification.chat_id}`;
                case "admin_message":
                    return `/admin/messages-list/`; // Link to the chat with unread messages
            default:
                return `/admin/job-details/${notification.job_id}`;
        }
    };

    // Function to render notification content based on type
    const renderNotificationContent = (notification) => {
        switch (notification.type) {
            case 'new_comment':
                return (
                    <>
                        <b>Neue Notiz</b> <br />
                        <small>Job-ID: {notification.job_id}</small> <br />
                        <small>{notification.job_function} (M/W/D) {notification.profession}</small> <br />
                        <small>unternehmen: {notification.company_name}</small> <br />
                    </>
                );
            case 'new_contract':
                return (
                    <>
                        <b>Vermittlungsvereinbarung zur Unterschrift</b> <br />
                        <small>Unternnehmen: {notification.company_name}</small> <br />
                        <small>{notification.job_function} (M/W/D) {notification.profession}</small> <br />
                    </>
                );
            case 'new_document':
                return (
                    <>
                        <b>Neue Dokumente von {notification.company_name}</b> <br />
                        <small>Dokumenttitel: {notification.doc_title}</small> <br />
                        <small>{notification.job_function} (M/W/D) {notification.profession}</small> <br />
                    </>
                );
            case 'new_message':
                return (
                    <>
                        <b>Neue Nachricht von {notification.sender_first_name} {notification.sender_last_name}</b> <br />
                        <small>{notification.job_function} (M/W/D) {notification.profession}</small> <br />
                        <small>Unternehmen: {notification.company_name}</small> <br />
                    </>
                );
                case "admin_message":
                    return (
                      <>
                        <b>
                          Neue Nachricht von Hello-Doctor-Team{" "}
                        </b>{" "}
                        <br />
                        <small>{notification.message}</small> <br />
                        <small>
                          Gesendet am: {new Date(notification.timestamp).toLocaleString()}
                        </small>
                      </>
                    );
            default:
                return (
                    <>
                        <b>{notification.candidate_status}</b><br></br>
                        <small>Unternehmen: {notification.company_name}</small> <br />
                        <small>{notification.job_function}  (M/W/D) {notification.profession}</small><br />
                    </>
                );
        }
    };

    return (
        <>
            <UncontrolledDropdown nav ref={dropdownRef}>
                <DropdownToggle nav style={{ fontSize: "18px", position: 'relative', top: "1px" }}>
                    🔔 {notifications && notifications.filter(n => !n.admin_seen).length}
                </DropdownToggle>
                <DropdownMenu right
                    style={{ overflowY: 'scroll', marginLeft: '-178px', maxHeight: maxHeight, maxWidth: '400px' }}
                >
                    {notifications && notifications.slice(0, 359).map((notification, index) => (
                        <DropdownItem
                            key={index}
                            tag={Link}
                            to={getNotificationLink(notification)}
                            onClick={() => handleNotificationClick(notification)}
                        >
                            {renderNotificationContent(notification)}
                            <hr />
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
        </>
    );
}

export default NotificationDropdown;
