import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, Collapse } from "reactstrap";
import PerfectScrollbar from "perfect-scrollbar";
import axios from 'axios';
var ps;
class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.getCollapseStates(props.routes);
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = routes => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }
  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = routes => {
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.invisible) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state[prop.state];
        return (
          <li
            className={this.getCollapseInitialState(prop.views) ? "active" : ""}
            key={key}>
            <a
              href="#pablo"
              data-toggle="collapse"
              aria-expanded={this.state[prop.state]}
              onClick={e => {
                e.preventDefault();
                this.setState(st);
              }}>
              {prop.icon !== undefined ? (
                <>
                  <i className={prop.icon} />
                  <p>
                    {prop.name}
                    <b className="caret" />
                  </p>
                </>
              ) : (
                <>
                  <span className="sidebar-mini-icon">{prop.mini}</span>
                  <span className="sidebar-normal">
                    {prop.name}
                    <b className="caret" />
                  </span>
                </>
              )}
            </a>
            <Collapse isOpen={this.state[prop.state]}>
              <ul className="nav">{this.createLinks(prop.views)}</ul>
            </Collapse>
          </li>
        );
      }
      return (
        <li className={this.activeRoute(prop.layout + prop.path)} key={key}>
          <NavLink to={prop.layout + prop.path} activeClassName="">
            {prop.icon !== undefined ? (
              <>
                <i className={prop.icon} />
                <p>{prop.name}</p>
              </>
            ) : (
              <>
                <span className="sidebar-mini-icon">{prop.mini}</span>
                <span className="sidebar-normal">{prop.name}</span>
              </>
            )}
          </NavLink>
        </li>
      );
    });
  };
  activeRoute = routeName => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  componentDidMount() {
    axios.post("/api/profilename", this.state)
   .then(response => { 
    const matchStatus = response.data[0].match_status;
    this.setState({matchStatus})
    console.log(matchStatus)
    if(matchStatus === "aktiv"){
     
      this.setState({green : true , red : false})
    }else{
      const title = title1;
      this.setState({green : false , red : true})
    }
    const title1 = response.data[0].title;
    this.setState({title1})
    if(title1 === "kein"){
      const title = '';
      this.setState({title})
    }else{
      const title = title1;
      this.setState({title})
    }
    localStorage.setItem('title',JSON.stringify(this.state.title));
    const andre = response.data[0].andre;
    this.setState({andre})
    localStorage.setItem('andre',JSON.stringify(this.state.andre));
    const f_name = response.data[0].f_name;
    this.setState({f_name})
    localStorage.setItem('f_name',JSON.stringify(this.state.f_name));
    const lname = response.data[0].l_name;
    this.setState({lname})
    localStorage.setItem('lname',JSON.stringify(this.state.lname));
    const user_profile = response.data[0].picture;
    this.setState({user_profile})
    localStorage.setItem('user_profile',JSON.stringify(this.state.user_profile));
 })
 .catch(error => {
})
/////////////////////////////
axios.post("/api/profilestatuscount",this.state)
.then(response =>{
const progress = response.data.totalprofilecount;
this.setState({progress})
})
.catch(error =>{
  const progress = error.response.data.totalprofilecount;
this.setState({progress})
})
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false
      });
    }
  }

  componentWillMount(){
    const user_id = JSON.parse(localStorage.getItem('user_id'))
    this.setState({user_id})
   }
  componentWillUnmount() {
    clearTimeout(this.intervalID);
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
    
  }
  render() {
    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}>
        <div className="logo">
          <a
            href="https://hello-doctor.de" target="_blank" rel="noopener noreferrer"
            className="simple-text logo-mini">
            
          </a>
          <a
            // href="https://hello-doctor.de/dashboardpage"
            href="/admin/dashboardpage"
            className="simple-text logo-normal" rel="noopener noreferrer">
            <img src="../../../../../../../../../../../img2/logo1.png" width="110" height ="80"></img>
          </a>
        </div> 

        <div className="sidebar-wrapper" ref="sidebar">
        <Nav>{this.createLinks(this.props.routes)}</Nav>
        </div>
      </div>
    );
  }
}

export default Sidebar;
         {/*  <div className="user">
          <div className="photo">
            {this.state.user_profile === null ? null : 
            <img src={`https://hello-doctor.de/profile/${this.state.user_profile}`} alt="Avatar" /> 
            }  
            </div>
            <div className="info">
              <Link
                to="/admin/candidate-profilee"
                >
                <span>
             {this.state.f_name}
                </span>
              </Link>
            </div>
        
            <Row>
             <Col style={{
               fontSize:'12px',
               position: 'relative',
               right: '65px',
               marginTop: '18px',
             }}>
             Profil Status
             </Col>
             <Col style={{
               fontSize:'12px',
               position: 'relative',
               top: '18px',
               left: '25px',
             }}>
             <Link to="/admin/candidate-profilee">
             <i class="fas fa-edit"></i>
             </Link>
             </Col>
             </Row> */}
            {/*    <div>
                <span style={{
               color : '#ffffff',
               fontSize:'12px',
               position: 'relative',
              //  right: '50px',
               marginTop: '18px',
             }}>Profil Status</span>
            
             <Link to="/admin/candidate-profilee">
             <i style={{
               fontSize:'12px',
               position: 'relative',
               left: '70px',
               marginTop: '18px',
             }} class="fas fa-edit"></i>
             </Link>
           
           <Progress style={{
             marginLeft: '19px',
             width: '222px'
           }}  color="success" value={this.state.progress}>{this.state.progress+'%'}</Progress>
        <hr style={{
             position: 'relative',
             left: '13px',
             right: '15px',
             height: '1px',
             width: 'calc(100% - 28px)',
             backgroundColor: 'rgba(255, 255, 255, 0.3)'
           }}></hr>
          <Row>
             <Col style={{
               fontSize:'12px',
               position: 'relative',
               right: '-19px',
               color : '#ffffff'
             }}>
             Matching Status
             </Col> 
             <Col style={{
               fontSize:'12px',
               position: 'relative',
               top: '0px',
               left: '18px',
             }}>
               {this.state.green ? <span style = {{color : '#28a745'}}>{this.state.matchStatus}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span> : null }
               {this.state.red ? <span style = {{color : '#ff0000'}}>{this.state.matchStatus}&nbsp;</span> : null } 
           <Link to="/admin/account">
             <i class="fas fa-edit"></i>
             </Link>
             </Col>
             </Row> 
           </div>
           
            
          </div>    */}
              
      
